@tailwind base;
@tailwind components;
@tailwind utilities;

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "leaflet/dist/leaflet.css";

@layer base {
  h1 {
    @apply highlight-title text-5xl;
  }

  h2 {
    @apply text-app-red text-3xl;
  }

  h3 {
    @apply text-lg;
  }
}

@layer components {
  .highlight-title {
    @apply text-app-red font-bold;
  }
}

body {
  margin: 0;
  font-size: 12px;
  color: #383838;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

.bg-gray {
  background-color: #fafafa;
}

* {
  scroll-margin-top: 70px;
  scroll-behavior: smooth;
}

.ant-btn-primary {
  background-color: #bb401d;
}
